<template>
	<ion-page id="kursdetail">
		<ion-header :translucent="true">
			<ion-toolbar color="success">
				<ion-buttons slot="start">
					<ion-button @click="() => router.replace(`/kurse`)">
						&lt; Zurück
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true" :key="$route.params.id">
			<div id="greeting" :style="`background-image: url(${imgLink})`">
				<div class="container">
					<div class="tag" v-if="advertorial">GESPONSERT</div>
					<h1 class="slabs">
						{{ kurs.name }}
					</h1>
					<p>{{ kurs.beschreibung }}</p>
					<div class="label" v-if="kurs.kapitel && userProgress">
						{{ userProgress.doneKapitel }}/{{ kurs.kapitel.length }}
						KAPITEL
					</div>
				</div>
			</div>
			<ion-list>
				<ion-item
					:detail="true"
					:router-link="kapitelLink(kapitel)"
					v-for="kapitel in kurs.kapitel"
					:key="kapitel.id"
					replace
				>
					<ion-label class="listitem">
						<h2>{{ kapitel.name }}</h2>
						<p>{{ kapitel.beschreibung }}</p>
						<IonProgressBar
							:color="'primary'"
							:value="kapitelCompleted(kapitel)"
						></IonProgressBar>
					</ion-label>
				</ion-item>
			</ion-list>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	IonButtons,
	IonContent,
	IonHeader,
	IonButton,
	IonPage,
	IonLabel,
	IonList,
	IonItem,
	IonToolbar,
	IonProgressBar,
} from '@ionic/vue';
import { useRouter } from 'vue-router';

export default {
	name: 'KursDetail',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonButton,
		IonPage,
		IonLabel,
		IonList,
		IonItem,
		IonToolbar,
		IonProgressBar,
	},
	data() {
		return {
			kurs: {},
		};
	},
	computed: {
		imgLink() {
			if (!this.kurs.featured_image) return;
			return `${this.$store.state.imageBase}${
				this.kurs.featured_image
			}?key=newscardlg&access_token=${localStorage.getItem(
				'auth_token'
			)}`;
		},
		userProgress() {
			if (!this.$store.state.user || !this.$store.state.user.progress)
				return { doneKapitel: 0, kapitel: {}, done: false };
			return this.$store.state.user.progress[this.$route.params.id];
		},
		advertorial() {
			return this.kurs.advertorial;
		},
		onboarding() {
			return this.kurs.onboarding;
		},
		proColour() {
			let color = '';
			let prog = parseFloat(this.progress);
			switch (prog) {
				case 0:
					color = 'dark';
					break;
				case 1:
					color = 'primary';
					break;
				default:
					color = 'secondary';
			}
			return color;
		},
	},
	methods: {
		async ionViewDidEnter() {
			this.onCreated();
		},
		async onCreated() {
			if (!this.$route.params.id) return this.$destroy();
			const { data } = await this.$http.get(
				`/items/kurse/${this.$route.params.id}?fields=*,kapitel.*,kapitel.module.*,advertorial,onboarding`
			);
			this.kurs = data.data;
			this.kurs.kapitel.sort((a, b) => a.sort - b.sort);
			this.$forceUpdate();
			// Create progress if not already there
			if (
				!this.$store.state.user.progress ||
				!this.$store.state.user.progress[this.$route.params.id]
			) {
				let progress = this.$store.state.user.progress || {};
				progress[this.$route.params.id] = {
					done: false,
					doneKapitel: 0,
					kapitel: {},
				};
				await this.$store.dispatch('updateUserProgress', { progress });
			}
		},
		kapitelLink(kapitel) {
			if (this.advertorial || this.onboarding)
				return `/advertorial/${this.$route.params.id}/${this.$slugify(
					this.kurs.name
				)}/kapitel/${kapitel.id}/modul/${this.firstModule(kapitel)}`;
			return `/kurs/${this.$route.params.id}/${this.$slugify(
				this.kurs.name
			)}/kapitel/${kapitel.id}/modul/${this.firstModule(kapitel)}`;
		},
		kapitelCompleted(kapitel) {
			if (
				!kapitel ||
				!this.userProgress ||
				!this.userProgress.kapitel ||
				!this.userProgress.kapitel[kapitel.id]
			)
				return 0;
			return (
				this.userProgress.kapitel[kapitel.id].doneModules /
				kapitel.module.length
			);
		},
		firstModule(kapitel) {
			if (
				!kapitel ||
				!('module' in kapitel) ||
				!this.userProgress ||
				!this.userProgress.kapitel
			)
				return 0;
			const modules = kapitel.module.sort(
				(a, b) => a.sort - b.sort || a.id - b.id
			);

			// Needed to make empty draft kapitel work
			if (!Object.keys(modules).length) return 0;
			const thisProgress = this.userProgress.kapitel[kapitel.id];
			let goTo = modules[0].id;
			if (
				thisProgress &&
				thisProgress.doneModules &&
				thisProgress.doneModules > 0
			) {
				for (let mod of modules) {
					if (!thisProgress.modules[mod.id]) {
						goTo = mod.id;
						break;
					}
				}
			}
			return goTo;
		},
	},
	setup() {
		const router = useRouter();
		return { router };
	},
};
</script>

<style lang="scss" scoped>
#kursdetail {
	#greeting {
		height: 350px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		margin-bottom: 0px;
		border-bottom: 1px solid var(--ion-color-light);

		.toolbar-title {
			font-size: 20px;
		}

		.container {
			background-color: rgba(255, 255, 255, 0.8);
			position: absolute;
			bottom: 0;
			left: 0;
			height: 120px;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			flex-direction: column;
			padding: 1rem;
			h1 {
				font-weight: bold;
				margin-top: 0;
			}
			p {
				margin-top: 0;
				margin-bottom: 10px;
			}
			.label {
				font-size: 14px;
				color: var(--ion-color-dark);
				margin-top: 0px;
			}
		}
	}

	ion-item {
		cursor: pointer;
	}

	.listitem {
		h2 {
			font-size: 18px;
			color: var(--ion-color-black);
		}
		p {
			margin: 5px 0px 10px 0px;
			font-size: 15px;
			color: var(--ion-color-medium);
			white-space: normal;
		}
	}

	.tag {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0.2rem;
		background-color: var(--ion-color-tertiary);
		color: var(--ion-color-dark);
		display: inline;
		padding-left: 2px;
		padding-right: 2px;
	}
}
</style>
